<template>
  <div class="min-h-screen h-full">
    <TsHeader data-testid="header" />

    <div>
      <slot />
    </div>

    <TsProductActions
      v-if="featureFlags.enableCTAAddToTrolleyBuyNow"
    />

    <TsProductActionsv2
      v-else-if="featureFlags.enableCTACollectionDelivery"
    />

    <TsFooter data-testid="footer" />
  </div>
</template>

<script lang="ts" setup>
const runtimeConfig = useRuntimeConfig();
const featureFlags = runtimeConfig.public.featureFlags;
</script>
